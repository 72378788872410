//------------------------------------------------------------------------
// Setup Font Face Observer to check when fonts have loaded
// https://fontfaceobserver.com
// https://www.filamentgroup.com/lab/font-events.html
// https://jonsuh.com/blog/font-loading-with-font-events/
// Note: Now that `font-display` is supported in some browsers, check for support first.
//       Once all modern browsers support it, consider loading fontfaceobserver asynchronously,
//       and possibly adding the Promise polyfill via Bable instead of bundling it in fontfaceobserver.
// http://caniuse.com/#feat=css-font-rendering-controls
// https://css-tricks.com/font-display-masses/#article-header-id-3
//------------------------------------------------------------------------

// TODO: Use Font Loading API when supported, dynamically load Font Face Observer when not supported

// Font face observer script allows us to detect when web fonts have been downloaded
// NOTE: The main file in “fontfaceobserver” doesn’t include the Promise polyfill so we’re linking to the one that does
import FontFaceObserver from 'fontfaceobserver/fontfaceobserver';

const htmlEl = document.documentElement;

// Only run if fonts have not been previously loaded
if (
  !htmlEl.classList.contains("fonts-loaded") &&
  ("sessionStorage" in window && !sessionStorage.fontsLoaded)
) {
  var grad = new FontFaceObserver('Grad', { weight: 400 });
  var gradItalic = new FontFaceObserver('Grad', {
    weight: 400,
    style: 'italic'
  });
  // var grad700 = new FontFaceObserver('Grad', { weight: 700 });

  var gtAmerica = new FontFaceObserver('GT America', { weight: 400 });
  var gtAmericaItalic = new FontFaceObserver('GT America', {
    weight: 400,
    style: 'italic'
  });
  // var gtAmerica300 = new FontFaceObserver('GT America', { weight: 300 });
  var gtAmerica600 = new FontFaceObserver('GT America', { weight: 600 });
  // var gtAmerica700 = new FontFaceObserver('GT America', { weight: 700 });

  // Promise polyfill provided by fontfaceobserver.js
  Promise.all([
    grad.load(),
    gradItalic.load(),
    // grad700.load(),

    gtAmerica.load(),
    gtAmericaItalic.load(),
    // gtAmerica300.load(),
    gtAmerica600.load(),
    // gtAmerica700.load(),
  ]).then(function() {
      // Append loaded class to html element
      htmlEl.className += ' fonts-loaded';

      // Set session var so we can skip this code next time
      // Note: Use sessionStorage instead of localStorage because we can't be
      //       sure the fonts will still be cached in the future, so start
      //       fresh each time a user visits the site.
      if ('sessionStorage' in window) {
        sessionStorage.fontsLoaded = "true";
      }

      // Trigger window resize event to force expandable components to recalc their height
      window.dispatchEvent(new Event('resize'));
    })
    .catch(function() {
      console.warn('Could not resolve Promise that webfonts loaded');
    });
}
