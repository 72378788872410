//------------------------------------------------------------------------
// Open all external links in a new tab
// https://github.com/Threespot/kresge-cdfs/issues/63
//
// Also add rel="noopener" for better security
// https://developers.google.com/web/tools/lighthouse/audits/noopener
//------------------------------------------------------------------------
const init = () => {
  document.querySelectorAll('[href^="http"]').forEach(el => {
    // External link test from https://gist.github.com/jlong/2428561
    var a = document.createElement("a");
    a.href = el.href;
    if (a.hostname !== window.location.hostname) {
      el.target = "_blank";
      el.rel = "noopener";
    }
  });
};

// Init on load
init();
