//------------------------------------------------------------------------
// “Cities & Projects to Watch” carousel built with Flickity https://flickity.metafizzy.co
//------------------------------------------------------------------------
"use strict";

import Flickity from "flickity";
import "flickity-as-nav-for";
import debounce from "lodash/debounce";

var tabs = document.querySelector('.Cities-tabs');

if (tabs) {
  var options = {
    arrowShape: {
      x0: 10,
      x1: 60, y1: 50,
      x2: 65, y2: 45,
      x3: 20
    },
    cellAlign: 'center',
    contain: true,
    asNavFor: ".Cities-slides",
    pageDots: false,
    // watchCSS: true,
  }

  var flkty = new Flickity( tabs, options);

  var updateOptions = debounce(function(evt) {
    // contain items and disable draggable on large screens
    if ( matchMedia('screen and (min-width: 768px)').matches ) {
      options.cellAlign = 'center';
      options.contain = true;
      flkty = new Flickity( tabs, options);
    } else {
      options.cellAlign = 'left';
      options.contain = false;
      flkty = new Flickity( tabs, options);
    }
  }, 250)

  window.addEventListener('resize', updateOptions);
}

var slides = document.querySelector('.Cities-slides');

if (slides) {
  var flkty = new Flickity( slides, {
    arrowShape: {
      x0: 10,
      x1: 60, y1: 50,
      x2: 60, y2: 45,
      x3: 15
    },
    cellAlign: 'left',
    cellSelector: '.Cities-slides-item',
    draggable: true,
    hash: true,
    // imagesLoaded: true,
    pageDots: false,
    // setGallerySize: false
  });

  // On slower connections, the background image may not finish doewnloading
  // until after Flicky has already initialized and set an inline height.
  // To account for this, we’re listening to the onload event on the images
  // and manually telling Flickity to resize.
  // https://github.com/Threespot/kresge-cdfs/issues/68
  var bgImages = document.querySelectorAll(".Cities-slides-item-image");
  bgImages.forEach(img => {
    img.onload = () => {
      flkty.resize();
    };
  });
}
