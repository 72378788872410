//------------------------------------------------------------------------
// Terms & Topics expand toggle
//------------------------------------------------------------------------
import ExpandToggle from "@threespot/expand-toggle";

class CommunityExpandToggles {
  constructor() {
    this.toggles = document.querySelectorAll(".Communities-toggle[data-expands]");
    this.expandToggles = [];// placeholder to store ExpandToggle instances

    if (!this.toggles.length) {
      return false;
    }

    // Multi-column layout breakpoint (must match $bp-wide in _communities.scss)
    this.singleColMediaQuery = window.matchMedia("(max-width: 900px)");

    // Init expand toggles
    this.init();

    // Setup global event listeners
    this.globalEvents();
  }

  init() {
    // Init dropdown menus (primary and secondary navs)
    this.toggles.forEach(el => {
      let expandToggle = new ExpandToggle(el);

      // Push to placeholder array so we can control them later
      // e.g. in closeOther() and closeAll()
      this.expandToggles.push(expandToggle);

      // Close other dropdown menus when opening a new one
      expandToggle.on('expand', () => {
        this.closeOther(expandToggle);

        // Scroll to expanded content in single-column layout
        if (this.singleColMediaQuery.matches) {
          expandToggle.targetEl.scrollIntoView();
        }
      });
    });
  }

  globalEvents() {
    window.addEventListener("keydown", evt => {
      // Close menus with escape key
      if (evt.which === 27) {
        this.closeAll();
      }

      // Allow left/right arrow keys to toggle section
      if (evt.which === 37 || evt.which === 39) {
        // Only apply when Communities component has focus
        if (!document.activeElement.closest(".Communities")) {
          return false;
        }

        // Find the currently expanded section
        let activeSection = document.querySelector('.Communities-toggle[aria-expanded="true"]');

        if (!activeSection) {
          return false;
        }

        // Index of the currently expanded menu
        let activeSectionIndex = this.expandToggles.map(item => item.el).indexOf(activeSection);

        // Left arrow
        if (evt.which === 37 && activeSectionIndex > 0) {
          this.expandToggles[activeSectionIndex].collapse();
          this.expandToggles[activeSectionIndex - 1].expand();
          this.expandToggles[activeSectionIndex - 1].el.focus();
        } else if (evt.which === 39 && activeSectionIndex < this.expandToggles.length - 1) {
          // Right arrow
          this.expandToggles[activeSectionIndex].collapse();
          this.expandToggles[activeSectionIndex + 1].expand();
          this.expandToggles[activeSectionIndex + 1].el.focus();
        }
      }
    });
  }

  // Close all nav dropdown menus
  closeAll() {
    this.expandToggles.forEach(toggle => {
      toggle.collapse();
    });
  }

  // Close all nav dropdwn menus except the current one
  closeOther(currentToggle) {
    this.expandToggles.forEach(toggle => {
      if (currentToggle !== toggle) {
        toggle.collapse();
      }
    });
  }
}

// Init
new CommunityExpandToggles();
