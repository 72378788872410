// ES6 module
import Unorphanize from "@threespot/unorphanize";

// For transpiled ES5 code, import this file:
// import Unorphanize from "@threespot/unorphanize/dist/unorphanize.m";

const nodes = document.querySelectorAll("[data-orphans]");

nodes.forEach(function(el) {
  var u = new Unorphanize(el, {
    wordCount: 2, // accepts any integer
    wrapEl: "span", // accepts any tag name
    className: "u-nowrap", // accepts any valid class name
    append: "" // accepts any arbitrary HTML
  });
});
