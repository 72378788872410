//------------------------------------------------------------------------
// This file contains modules that DO NOT depend on the CSSOM and can be
// executed as soom as the CSSOM is ready. It’s set to “defer” in the HTML.
//------------------------------------------------------------------------

// Picture element polyfill
// Required for IE 11- and Android 4.4-
// http://caniuse.com/#feat=picture
import 'picturefill';

// Detect input method in order to hide outlines in an accessible manner
// https://github.com/ten1seven/what-input
import 'what-input';

// “scroll-behavior: smooth” polyfill (required for Edge 18, Safari/iOS 13)
// https://github.com/cferdinandi/smooth-scroll
// https://caniuse.com/#feat=css-scroll-behavior
import SmoothScroll from 'smooth-scroll';
if (!("scrollBehavior" in document.documentElement.style)) {
  const smoothScroll = new SmoothScroll('a[href*="#"]:not([data-modal])', {
    easing: 'easeInCubic',
    speed: 700,
    speedAsDuration: true,
    updateURL: false
  });
}

// Modules
import '../modules/background-picture';
import '../modules/communities-expand';
import '../modules/external-links';
import '../modules/font-face';
import '../modules/gallery';
import '../modules/homepage-mobile-nav';
import '../modules/link-icons';
import '../modules/mailto';
import '../modules/orphans';
import '../modules/scrollable-tables';
import '../modules/terms-expand';
import '../modules/youtube';
// import './modules/form-validation';
// import './modules/soundcloud';
// import './modules/vimeo';
// import '../modules/tabs';
