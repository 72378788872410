//------------------------------------------------------------------------
// Append icons to links and prevent them from wrapping
//
// TODO: Could refactor to move constants to separate files
//------------------------------------------------------------------------
import Unorphanize from "@threespot/unorphanize";

// Use simple rounding function since we only need one decimal place.
// This apprach has issues when rounding to more decimal places:
// e.g. Math.round(1.005 * 100) / 100; // 1 instead of 1.01
// http://www.jacklmoore.com/notes/rounding-in-javascript/
const roundSingleDecimal = function(number) {
  return Math.round(number * 10) / 10;
};

// When more decimal places are desired, see this MDN page for a better function:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round#A_better_solution
//
// function round(number, precision) {
//   var shift = function (number, exponent) {
//     var numArray = ("" + number).split("e");
//     return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + precision) : precision));
//   };
//   return shift(Math.round(shift(number, +exponent)), -exponent);
// }

const icons = {
  chev: {
    class: "icon icon-chev",
    viewBox: "0 0 11 20",
    width: 7,
    // height: 13, // optionally set the default height
    path: `<path d="M1.1 0L0 1.1l8.9 8.8L0 18.8l1.1 1.1L11 10z"/>`
    // customAttrs: '' // optional additional attrs (e.g. fill="none" stroke="#fff" stroke-width="1")
  },
  external: {
    class: "icon icon-external",
    viewBox: "0 0 16 16",
    width: 13,
    path: `<path d="M11 13.8H1.4V4.1h6.2l1.3-1.3H0v12.3h12.4V6.2L11 7.6z"/><path d="M14.4 0H8.9v1.4h3.9L4.3 9.8l1 1 8.5-8.4v3.8h1.3V0z"/>`
  }
};

const buildSVG = function(icon) {
  return `
    <svg class="${icon.class}" viewBox="${icon.viewBox}" width="${icon.width}" height="${icon.height}" preserveAspectRatio="xMidYMid meet" aria-hidden="true" focusable="false" ${'customAttrs' in icon ? icon.customAttrs : ''}>
      ${icon.path}
    </svg>`;
};

const fileTypes = ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"];

const init = () => {
  document.querySelectorAll('[data-icon], .u-richtext a:not([class]):not([href^="#"]), .GetStarted-link').forEach(el => {
    let iconName = el.getAttribute("data-icon");

    // If link is external, use external icon
    // External link test from https://gist.github.com/jlong/2428561
    var a = document.createElement("a");
    a.href = el.href;
    if (a.hostname !== window.location.hostname) {
      iconName = "external";
    }

    // Check if icon exists
    if (!(iconName in icons)) {
      console.warn(`Icon “${iconName}” was not found in link-icons.js`, el);
      return false;
    }

    // Automatically change the icon if it’s external or a download link
    // NOTE: This is project-specific, edit as needed.
    // if (!el.classList.contains("EXAMPLE-dont-change-icon")) {
    //
    //   // If link is external, use external icon (excluding certain CTA links/buttons below)
    //   // External link test from https://gist.github.com/jlong/2428561
    //   var a = document.createElement("a");
    //   a.href = el.href;
    //   if (a.hostname !== window.location.hostname) {
    //     iconName = "external";
    //   }
    //
    //   // Check if link is a file download
    //   let fileExt = a.pathname.split(".").pop();
    //   console.log(fileExt);
    //
    //   if (fileTypes.indexOf(fileExt) > -1) {
    //     iconName = "download";
    //   }
    // }

    // Create new object for this icon so we can change
    // the dimensions without affecting the defaults.
    const icon = {};

    // Copy values from original icons object
    Object.assign(icon, icons[iconName]);

    // Check for custom size attributes
    let iconHeight = el.getAttribute("data-icon-height");
    let iconWidth = el.getAttribute("data-icon-width");

    // Validate height and width values if present
    // Note: Use unary plus (+) operator to convert strings to numbers
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Arithmetic_Operators#Unary_plus_()
    if (iconHeight) {
      if (isNaN(+iconHeight)) {
        console.warn(`Can’t parse data-icon-height value of “${iconHeight}” on ${el}`);
        return false;
      } else {
        icon.height = +iconHeight;
      }
    }

    if (iconWidth) {
      if (isNaN(+iconWidth)) {
        console.warn(`Can parse data-icon-width value of “${iconWidth}” on ${el}`);
        return false;
      } else {
        icon.width = +iconWidth;
      }
    }

    // Make sure either the height or width has been defined
    if (!icon.height && !icon.width) {
      console.warn(`No height or width defined for icon “${iconName}”`, icon);
      return false;
    }

    // Calculate height or width if only one dimension was provided
    // Note: We can’t rely on CSS to resize SVGs in IE11-
    //       because IE doesn’t respect the viewBox ratio.
    let viewBoxArr = icon.viewBox.split(" ");

    // Validate viewBox value
    if (viewBoxArr.length !== 4) {
      console.warn(`Icon “${iconName}” has a malformed viewBox attribute: “${icon.viewBox}”`);
      return false;
    }

    // Calculate aspect ratio
    let aspectRatio = +viewBoxArr[2] / +viewBoxArr[3];

    // Calculate height if width was provided
    if (!icon.height && icon.width) {
      icon.height = roundSingleDecimal(icon.width / aspectRatio);
    }

    // Calculate width if height was provided
    if (!icon.width && icon.height) {
      icon.width = roundSingleDecimal(icon.height * aspectRatio);
    }

    // Insert the icon using Unorphanize to prevent wrapping
    new Unorphanize(el, {
      inlineStyles: false,
      className: "u-nowrap",
      append: buildSVG(icon)
    });
  });
};

// Init on initial page load
init();
